import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBanners(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/banners/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchBanner(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/banners`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addBanner(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/banners", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteBanner(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/banners", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/banners/banner_status`, { id: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
